import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Order } from "../../helpers/TableHelpers";
import {DiscoveryCycleQueryParams, DiscoveryCyclesRepository} from "../../repositories/DiscoveryCyclesRepository";
import { DiscoveryCycle } from "../../models/Statistics/DiscoveryCycle";
import {MigrationType} from "../../models/Statistics/MigrationType";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import {
  getEventStepTypeColor,
  MigrationEventStepLabels,
  MigrationEventStepType
} from "../../models/Statistics/MigrationEventStepType";
import { convertUtcToLocal } from "../../helpers";
import {
  Card,
  CardContent,
  Divider,
  Skeleton,
  Box,
  Chip,
  Sheet,
  Table,
  Typography,
  FormControl,
  FormLabel,
  IconButton,
  Select,
  Option
} from "@mui/joy";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface CasesTableProps {
  label: string;
  queryKey: string;
  migrationType: MigrationType
  organizationId: string;
  queryParams: DiscoveryCycleQueryParams;
  shouldFetchDiscoveryCycles: boolean;
  setShouldFetchDiscoveryCycles: React.Dispatch<React.SetStateAction<boolean>>
}

const DiscoveryCycleTable: React.FC<CasesTableProps> = ({
  label,
  queryKey,
  migrationType,
  organizationId,
  queryParams,
  shouldFetchDiscoveryCycles,
  setShouldFetchDiscoveryCycles,
}) => {
  const discoveryCyclesRepository = new DiscoveryCyclesRepository();
  const [order, setOrder] = useState<Order>("desc");
  const [sort, setSort] = useState<string>("startDate");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [discoveryCycles, setDiscoveryCycles] = useState<DiscoveryCycle[]>([]);
  const [rows, setRows] = useState(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const discoveryCyclesQuery = useQuery(
    [queryKey, organizationId, page, rowsPerPage, sort, order, queryParams, migrationType],
    async () => {
      return await discoveryCyclesRepository.getDiscoveryCycles({
        organizationId: organizationId,
        migrationType: migrationType,
        params: queryParams,
        descending: order === "desc",
        orderBy: sort,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      });
    },
    {
      enabled: shouldFetchDiscoveryCycles,
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    },
  );
  const { isError, isFetching } = discoveryCyclesQuery;

  useEffect(() => {
    if (discoveryCyclesQuery.data?.data) {
      setDiscoveryCycles(discoveryCyclesQuery.data?.data);
      setRows(discoveryCyclesQuery.data.paging?.totalCount ?? 0)
      setShouldFetchDiscoveryCycles(false);
    }
  }, [discoveryCyclesQuery]);
  
  const changeSort = (newSort: string) => {
    setShouldFetchDiscoveryCycles(true);
    setPage(0);
    setSort(newSort);
  };

  const changeOrder = (newOrder: Order) => {
    setShouldFetchDiscoveryCycles(true);
    setPage(0);
    setOrder(newOrder);
  };

  const handleChangePage = (newPage: number) => {
    setShouldFetchDiscoveryCycles(true);
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setShouldFetchDiscoveryCycles(true);
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1)
      return (page + 1) * rowsPerPage;
    
    return rowsPerPage === -1
      ? rows
      : Math.min(rows, (page + 1) * rowsPerPage);
  };

  function labelDisplayedRows({from, to, count}: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  
  return (
    <Card variant="outlined" sx={{ mt: 1.5 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography level="h4" component="div">
            {label}
          </Typography>
          <IconButton onClick={toggleExpand} size="sm" sx={{ color: "white" }}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      
        {isExpanded && (
          <>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Sheet
            variant="outlined"
            sx={{
              display: { xs: "initial" },
              width: "100%",
              borderRadius: "sm",
              flexShrink: 1,
              overflowY: "auto",
              maxHeight: "490px",
              minHeight: 0,
            }}
          >
            <Skeleton variant="inline" loading={isFetching || isError}>
              <Table
                stickyHeader
                stickyFooter
                sx={{
                  "--TableCell-headBackground":
                    "var(--joy-palette-background-level1)",
                  "--Table-headerUnderlineThickness": "1px",
                  "--TableRow-hoverBackground":
                    "var(--joy-palette-background-level1)",
                  "--TableCell-paddingY": "4px",
                  "--TableCell-paddingX": "8px",
                }}
              >
                <thead>
                <tr>
                  <th style={{width: "1.5%"}}></th>
            
                  <th style={{width: "30%", padding: "12px 6px"}}>
                    <SortableTableColumnHeader
                      label={"Client ID"}
                      sortProperty={"createdBy"}
                      getSort={() => sort}
                      setSort={(newSort) => changeSort(newSort)}
                      getOrder={() => order}
                      setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                  </th>
                  
                  <th style={{width: "19%", padding: "12px 6px"}}>
                    <SortableTableColumnHeader
                      label={"Event Status"}
                      sortProperty={"eventStep"}
                      getSort={() => sort}
                      setSort={(newSort) => changeSort(newSort)}
                      getOrder={() => order}
                      setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                  </th>
            
                  <th style={{width: "25%", padding: "12px 6px"}}>
                    <SortableTableColumnHeader
                      label={"Start Date"}
                      sortProperty={"startDate"}
                      getSort={() => sort}
                      setSort={(newSort) => changeSort(newSort)}
                      getOrder={() => order}
                      setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                  </th>
            
                  <th style={{width: "25%", padding: "12px 6px"}}>
                    <SortableTableColumnHeader
                      label={"End Date"}
                      sortProperty={"endDate"}
                      getSort={() => sort}
                      setSort={(newSort) => changeSort(newSort)}
                      getOrder={() => order}
                      setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                  </th>
                </tr>
                </thead>
            
                <tbody>
                {discoveryCycles.map((discoveryCycle) => (
                  <tr key={discoveryCycle.id}>
                    <td></td>
                    <td>{discoveryCycle.clientId}</td>
                    <td>
                      <Chip
                        sx={{textAlign: "center"}}
                        variant="soft"
                        size="sm"
                        color={getEventStepTypeColor(discoveryCycle.eventStep)}
                      >
                        {MigrationEventStepLabels[discoveryCycle.eventStep]}
                      </Chip>
                    </td>
                    <td>{convertUtcToLocal(discoveryCycle.startDate)}</td>
  
                    {
                      discoveryCycle.eventStep != MigrationEventStepType.Started ? (
                        <td>{convertUtcToLocal(discoveryCycle.endDate)}</td>
                      ) : (
                        <td>-</td>
                      )
                    }
                    
                  </tr>
                ))}
                </tbody>
            
                <tfoot>
                  <tr>
                    <td colSpan={9}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <FormControl orientation="horizontal" size="sm">
                          <FormLabel>Rows per page:</FormLabel>
                          <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                            <Option value={10}>10</Option>
                            <Option value={25}>25</Option>
                            <Option value={50}>50</Option>
                            <Option value={100}>100</Option>
                          </Select>
                        </FormControl>
            
                        <Typography textAlign="center" sx={{minWidth: 80}}>
                          {labelDisplayedRows({
                            from: rows === 0 ? 0 : page * rowsPerPage + 1,
                            to: getLabelDisplayedRowsTo(),
                            count: rows === -1 ? -1 : rows,
                          })}
                        </Typography>
            
                        <Box sx={{display: 'flex', gap: 1}}>
                          <IconButton
                            size="sm"
                            color="neutral"
                            variant="outlined"
                            disabled={page === 0}
                            onClick={() => handleChangePage(page - 1)}
                            sx={{bgcolor: 'background.surface'}}
                          >
                            <KeyboardArrowLeftIcon/>
                          </IconButton>
                          <IconButton
                            size="sm"
                            color="neutral"
                            variant="outlined"
                            disabled={
                              rows !== -1
                                ? page >= Math.ceil(rows / rowsPerPage) - 1
                                : false
                            }
                            onClick={() => handleChangePage(page + 1)}
                            sx={{bgcolor: 'background.surface'}}
                          >
                            <KeyboardArrowRightIcon/>
                          </IconButton>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Skeleton>
          </Sheet>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DiscoveryCycleTable;
