import React from "react";
import {OrganizationConfiguration} from "../../models/OrganizationConfiguration/OrganizationConfiguration";
import {ImplementationType} from "../../models/OrganizationConfiguration/ImplementationType";
import {CaseMigrationStrategy} from "../../models/OrganizationConfiguration/CaseMigrationStrategy";
import {MigrationStrategy} from "../../models/OrganizationConfiguration/MigrationStrategy";
import CaseMigrationStrategySelect from "./CaseMigrationStrategySelect";
import OutlinedDiv from "../common/OutlinedDiv";
import {getFormattedDate} from "../../helpers/StringFormatters";
import MigrationStrategySelect from "./MigrationStrategySelect";
import CaseNumberListControl from "./CaseNumberListControl";
import {
  Box,
  FormControl, 
  FormLabel,
  Grid,
  Input,
  Switch
} from "@mui/joy";

interface MigrationStrategySectionProps {
  value: OrganizationConfiguration;
  onChange: (updatedConfig: OrganizationConfiguration) => void;
}

const OrganizationFilesSynchronizationStrategySection: React.FC<
  MigrationStrategySectionProps
> = ({ value, onChange }) => {

  const handleMigrationStrategyChange = (
    strategy: MigrationStrategy | "",
    property: keyof OrganizationConfiguration,
  ) => {
    onChange({
      ...value,
      [property]: strategy,
    });
  };

  const handleCaseStrategySelectChange = (
    caseStrategies: CaseMigrationStrategy[],
  ) => {
    onChange({
      ...value,
      casesMigrationStrategy: caseStrategies,
    });
  };

  const handleCaseSpecifiedListChange = (caseSpecifiedList: string[]) => {
    onChange({
      ...value,
      caseSpecifiedList: caseSpecifiedList,
    });
  };
  
  const handleCasePriorityListChange = (casePriorityList: string[]) => {
    onChange({
      ...value,
      casePriorityList: casePriorityList,
    });
  };

  const openClosedSettingsVisibility = Array.isArray(value.casesMigrationStrategy)
    ? (value.casesMigrationStrategy.includes(CaseMigrationStrategy.Custom)
      ? 'block'
      : 'none')
    : 'none';

  const specifiedCaseNumbersVisibility = Array.isArray(value.casesMigrationStrategy)
    ? (value.casesMigrationStrategy.includes(CaseMigrationStrategy.AllCases)
      ? 'block'
      : 'none')
    : 'none';
  
  return (
    <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'nowrap'}}>
      
      <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <OutlinedDiv label="Cases Synchronization Strategy">
            
            <CaseMigrationStrategySelect
              value={
                value.casesMigrationStrategy || [
                  CaseMigrationStrategy.AllCases,
                  CaseMigrationStrategy.DateDescending,
                ]
              }
              onChange={handleCaseStrategySelectChange}
              required
            />

            <Box sx={{ display: openClosedSettingsVisibility }}>
              <OutlinedDiv label={""}>
                <Grid container spacing={2}>
                  <Grid xs={1}>
                    <FormControl sx={{ pb: 1, mt: -0.25 }}>
                      <FormLabel>Open</FormLabel>
                      <Box>
                        <Switch
                          sx={{mt: 0.5}}
                          checked={value.migrateOpenedCases}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            onChange({
                              ...value,
                              migrateOpenedCases: isChecked,
                              casesOpenedAfter: isChecked ? value.casesOpenedAfter : null,
                            });
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid xs={11}>
                    <FormControl
                      sx={{ pb: 1, marginTop: -0.25,  ml: 3 }}
                      disabled={!value.migrateOpenedCases}
                    >
                      <FormLabel>Opened After</FormLabel>
                      <Input
                        value={
                          value.casesOpenedAfter
                            ? getFormattedDate(value.casesOpenedAfter, "yyyy-MM-dd")
                            : ""
                        }
                        onChange={(event) =>
                          onChange({
                            ...value,
                            casesOpenedAfter: event.target.value
                              ? getFormattedDate(event.target.value, "yyyy-MM-dd")
                              : null,
                          })
                        }
                        size={"sm"}
                        type="date"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: -2}}>
                  <Grid xs={1}>
                    <FormControl 
                      sx={{ pb: 1, marginTop: -0.25 }}
                      disabled={value.implementationType === ImplementationType.Api}
                    >
                      <FormLabel>Closed</FormLabel>
                      <Box>
                        <Switch
                          sx={{mt: 0.5}}
                          checked={value.migrateClosedCases}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            onChange({
                              ...value,
                              migrateClosedCases: isChecked,
                              casesClosedAfter: isChecked ? value.casesClosedAfter : null,
                            });
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid xs={11}>
                    <FormControl
                      sx={{ marginTop: -0.25, ml: 3 }}
                      disabled={!value.migrateClosedCases || value.implementationType === ImplementationType.Api}
                    >
                      <FormLabel>Closed After</FormLabel>
                      <Input
                        value={
                          value.casesClosedAfter
                            ? getFormattedDate(value.casesClosedAfter, "yyyy-MM-dd")
                            : ""
                        }
                        onChange={(event) =>
                          onChange({
                            ...value,
                            casesClosedAfter: event.target.value
                              ? getFormattedDate(event.target.value, "yyyy-MM-dd")
                              : null,
                          })
                        }
                        size={"sm"}
                        type="date"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Box>

            <Box sx={{ display: specifiedCaseNumbersVisibility }}>
              <CaseNumberListControl
                label={"Specified case numbers"}
                values={value.caseSpecifiedList || []}
                onChange={handleCaseSpecifiedListChange}
                isTrialworks={
                  value.implementationType === ImplementationType.TrialWorks
                }
                isSourceApi={
                  value.implementationType === ImplementationType.Api
                }
              />
            </Box>

            <CaseNumberListControl
              label={"Prioritized case numbers"}
              values={value.casePriorityList || []}
              onChange={handleCasePriorityListChange}
              isTrialworks={
                value.implementationType === ImplementationType.TrialWorks
              }
              isSourceApi={
                value.implementationType === ImplementationType.Api
              }
            />
            
          </OutlinedDiv>
        </Box>
      </Grid>
      
      <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={1} sx={{ flexDirection: 'column', flexGrow: 1 }}>
          <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <MigrationStrategySelect
              value={
                value.providersMigrationStrategy ||
                MigrationStrategy.DateDescending
              }
              onChange={(value) =>
                handleMigrationStrategyChange(
                  value,
                  "providersMigrationStrategy",
                )
              }
              label="Providers Synchronization Strategy"
              required
              disabled={value.implementationType == ImplementationType.Api}
            />
          </Grid>
          <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <MigrationStrategySelect
              value={
                value.firmDocumentsMigrationStrategy ||
                MigrationStrategy.DateDescending
              }
              onChange={(value) =>
                handleMigrationStrategyChange(
                  value,
                  "firmDocumentsMigrationStrategy",
                )
              }
              label="Firm Documents Synchronization Strategy"
              required
              disabled={value.implementationType == ImplementationType.Api}
            />
          </Grid>
          <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <MigrationStrategySelect
              value={
                value.templateFilesMigrationStrategy ||
                MigrationStrategy.DateDescending
              }
              onChange={(value) =>
                handleMigrationStrategyChange(
                  value,
                  "templateFilesMigrationStrategy",
                )
              }
              label="Template Files Synchronization Strategy"
              required
              disabled={value.implementationType == ImplementationType.Api}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganizationFilesSynchronizationStrategySection;
