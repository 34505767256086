/* eslint-disable jsx-a11y/anchor-is-valid */
import { Delete } from "@mui/icons-material";
import ReorderIcon from "@mui/icons-material/Reorder";
import SortIcon from "@mui/icons-material/Sort";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../helpers";
import { Order, compareBy, stableSort } from "../../helpers/TableHelpers";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";

import { IconButton, Input, Switch } from "@mui/joy";

interface ClientConfigurationsTableProps {
  configurations: ClientConfiguration[];
  onConfigPropertyChange: (newValue: ClientConfiguration) => void;
  onDeleteConfig: (configId: string) => void;
}

const ClientConfigurationsTable: React.FC<ClientConfigurationsTableProps> = ({
  configurations,
  onConfigPropertyChange,
  onDeleteConfig,
}) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [sort, setSort] = React.useState<string>("dateCreated");
  const navigate = useNavigate();

  const sortedResults = sort
    ? stableSort<ClientConfiguration>(configurations, compareBy(order, sort))
    : configurations;
  return (
    <React.Fragment>
      <Sheet
        className="ClientConfigurationsTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "3%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>
              <th style={{ width: "25%", minWidth: 140, padding: "12px 6px" }}>
                <Link
                  underline="none"
                  component="button"
                  onClick={() => {
                    setOrder(order === "asc" ? "desc" : "asc");
                    setSort("name");
                  }}
                  fontWeight="lg"
                  endDecorator={
                    sort === "name" ? <SortIcon /> : <ReorderIcon />
                  }
                  sx={{
                    "& svg": {
                      transition: "0.2s",
                      transform:
                        order === "asc" && sort === "name"
                          ? "rotate(180deg) scaleX(-1)"
                          : "rotate(0deg)",
                    },
                    color: sort === "name" ? "primary" : "text.primary",
                  }}
                >
                  Name
                </Link>
              </th>
              <th style={{ width: "10%", minWidth: 140, padding: "12px 6px" }}>
                Service Version
              </th>
              <th style={{ width: "7%", minWidth: 140, padding: "12px 6px" }}>
                Default
              </th>
              <th style={{ width: "10%", minWidth: 140, padding: "12px 6px" }}>
                <Link
                  underline="none"
                  component="button"
                  onClick={() => {
                    setOrder(order === "asc" ? "desc" : "asc");
                    setSort("dateCreated");
                  }}
                  fontWeight="lg"
                  endDecorator={
                    sort === "dateCreated" ? <SortIcon /> : <ReorderIcon />
                  }
                  sx={{
                    "& svg": {
                      transition: "0.2s",
                      transform:
                        order === "asc" && sort === "dateCreated"
                          ? "rotate(180deg) scaleX(-1)"
                          : "rotate(0deg)",
                    },
                    color: sort === "dateCreated" ? "primary" : "text.primary",
                  }}
                >
                  Date Created
                </Link>
              </th>
              <th style={{ width: "20%", minWidth: 140, padding: "12px 6px" }}>
                Created By
              </th>
              <th style={{ width: "10%", minWidth: 140, padding: "12px 6px" }}>
                <Link
                  underline="none"
                  component="button"
                  onClick={() => {
                    setOrder(order === "asc" ? "desc" : "asc");
                    setSort("dateModified");
                  }}
                  fontWeight="lg"
                  endDecorator={
                    sort === "dateModified" ? <SortIcon /> : <ReorderIcon />
                  }
                  sx={{
                    "& svg": {
                      transition: "0.2s",
                      transform:
                        order === "asc" && sort === "dateModified"
                          ? "rotate(180deg) scaleX(-1)"
                          : "rotate(0deg)",
                    },
                    color: sort === "dateModified" ? "primary" : "text.primary",
                  }}
                >
                  Date Modified
                </Link>
              </th>
              <th style={{ width: "20%", minWidth: 140, padding: "12px 6px" }}>
                Modified By
              </th>
              <th
                style={{ width: "5%", minWidth: 140, padding: "12px 6px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {sortedResults.map((config) => {
              return (
                <tr
                  key={config.id}
                  onDoubleClick={() =>
                    navigate(
                      `/organization/${config.organizationId}/client-configuration/${config.id}`
                    )
                  }
                >
                  <td style={{ textAlign: "center", width: 80 }}></td>
                  <td>
                    <Input
                      sx={{ mr: 3 }}
                      required
                      error={!!!config.name}
                      onChange={(event) => {
                        const dirtyConfig = {
                          ...config,
                          name: event.target.value,
                        };
                        config.name = dirtyConfig.name;
                        onConfigPropertyChange(dirtyConfig);
                      }}
                      value={config.name || ""}
                      size="sm"
                    ></Input>
                  </td>
                  <td>
                    <Chip variant="soft" size="sm">
                      {config.serviceVersion ? config.serviceVersion : "Latest"}
                    </Chip>
                  </td>
                  <td>
                    <Switch
                      checked={config.isDefault}
                      onChange={(event) => {
                        const dirtyConfig = {
                          ...config,
                          isDefault: event.target.checked,
                        };
                        config.isDefault = event.target.checked;
                        if (config.isDefault) {
                          configurations.forEach((c) => {
                            if (c.id !== config.id) {
                              c.isDefault = false;
                            }
                          });
                        }
                        onConfigPropertyChange(dirtyConfig);
                      }}
                    />
                  </td>
                  <td>
                    <Typography level="body-sm">
                      {getFormattedDate(config.dateCreated as string)}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-sm">{config.createdBy}</Typography>
                  </td>
                  <td>
                    <Typography level="body-sm">
                      {getFormattedDate(config.dateModified as string)}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-sm">{config.modifiedBy}</Typography>
                  </td>
                  <td>
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      color="danger"
                      onClick={() => onDeleteConfig(config.id ?? "")}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
};

export default ClientConfigurationsTable;
