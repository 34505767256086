import {Box, Card, CardContent, Grid, Link, Select, Stack, Table, Typography, Option, FormControl, FormLabel, Skeleton, } from "@mui/joy";
import React, {useEffect, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import CasesStatisticsCard from "../components/Organization/CasesStatisticsCard";
import FirmDocumentsStatisticsCard from "../components/Organization/FirmDocumentsStatisticsCard";
import IntakesStatisticsCard from "../components/Organization/IntakesStatisticsCard";
import OverallStatisticsCard from "../components/Organization/OverallStatisticsCard";
import ProvidersStatisticsCard from "../components/Organization/ProvidersStatisticsCard";
import TemplatesStatisticsCard from "../components/Organization/TemplatesStatisticsCard";
import OutlinedDiv from "../components/common/OutlinedDiv";
import {roundToTwoDecimalPoints} from "../helpers";
import {OrganizationWithStatistics} from "../models/OrganizationWithStatistics";
import OrganizationsRepository from "../repositories/OrganizationsRepository";
import UsersRepository from "../repositories/UsersRepository";
import {User} from "../models/User";
import GenericAutocomplete from "../components/common/GenericAutocomplete";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Tooltip from '@mui/joy/Tooltip';

interface OrganizationPageProps {
  organizationId: string;
}

const OrganizationPage: React.FC<OrganizationPageProps> = ({
  organizationId,
}) => {
  const [organization, setOrganization] =
    useState<OrganizationWithStatistics | undefined>(undefined);
  const [users, setUsers] =
    useState<User[]>([]);
  const [selectedUser, setSelectedUser] =
    useState<{id: string, label: string} | undefined>(undefined);

  const queryClient = useQueryClient();
  const organizationsRepository = new OrganizationsRepository();
  const usersRepository = new UsersRepository();

  const organizationQuery = useQuery(
    ["organization", organizationId],
    async () => {
      return await organizationsRepository.getOrganization(organizationId);
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    },
  );

  const userQuery = useQuery(
    "users",
    async () => {
      return await usersRepository.getUsers();
    },
    {
      cacheTime: 5 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (organizationQuery.data) {
      setOrganization(organizationQuery.data);
      setSelectedUser({
        id: organizationQuery.data.assignedUser.id,
        label: organizationQuery.data.assignedUser.username,
      });
    }
    
    if (userQuery.data) {
      setUsers(userQuery.data.data);
    }
  }, [
    userQuery.data,
    organizationQuery.data,
    organizationId
  ]);

  const userOptions = users.map(user => ({
    id: user.id,
    label: user.username,
  }));
  
  const { isLoading, isError } = organizationQuery;
  if (isLoading || isError || !!!organization) {
    return <></>;
  }
  const { intactId, name, sharepointSiteUrl, migrationStatistics } =
    organization as OrganizationWithStatistics;
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = migrationStatistics;
  const allocatedSpace = migrationStatistics.allocatedSharepointSpaceInGb
    ? roundToTwoDecimalPoints(migrationStatistics.allocatedSharepointSpaceInGb)
    : null;
  const usedSpace = migrationStatistics.usedSharepointSpaceInGb
    ? roundToTwoDecimalPoints(migrationStatistics.usedSharepointSpaceInGb)
    : null;
  const usedPercentage =
    usedSpace && allocatedSpace
      ? `${((parseFloat(usedSpace) / parseFloat(allocatedSpace)) * 100).toFixed(
        2,
      )}%`
      : null;
  const shouldRenderSharepointInfo =
    sharepointSiteUrl || allocatedSpace || usedSpace;

  const handleSelectUser = async (selectedOption: { id: string; label: string }) => {
    setSelectedUser(selectedOption);
    try {
      await organizationsRepository.patchOrganizationAssignedUser(organizationId, selectedOption.id);
      await queryClient.invalidateQueries("organizations");
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <>
      <Box width="100%" height={"100%"} padding={0}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', my: 1}}>
          <Typography level="h2" textColor="text.primary" sx={{ pb: 1 }}>
            {intactId} - {name}
          </Typography>
          <Skeleton
            animation="pulse"
            variant="inline"
            loading={userQuery.isLoading}
          >
            <GenericAutocomplete
              options={userOptions}
              inputValue={selectedUser ?? {id: '', label: ''}}
              setValue={handleSelectUser}
              placeholder="Assigned user"
              width="350px"
              startDecorator={
                <Tooltip title="Assigned user" variant="solid">
                  <AssignmentIndIcon sx={{ fontSize: 26, mt: 0.4 }}/>
                </Tooltip>
              }
            />
          </Skeleton>
        </Box>

        <Grid container spacing={2} height={"100%"}>
          {shouldRenderSharepointInfo ?  (
            <Grid xs={12} lg={12} display={"flex"}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <Typography level="h3" sx={{ paddingBottom: "0px" }}>
                  Sharepoint
                </Typography>
                <CardContent>
                  <OutlinedDiv label="">
                    {sharepointSiteUrl && (
                      <Link
                        href={sharepointSiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                      >
                        {sharepointSiteUrl}
                      </Link>
                    )}

                    {usedSpace && (
                      <Typography level="body-md" textColor="text.primary">
                        Used Space (GB): {usedSpace} ({usedPercentage})
                      </Typography>
                    )}
                    {allocatedSpace && (
                      <Typography level="body-md" textColor="text.primary">
                        Allocated Space (GB): {allocatedSpace}
                      </Typography>
                    )}
                  </OutlinedDiv>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
            
          <Grid xs={12} lg={12} display={"flex"}>
            <OverallStatisticsCard statistics={migrationStatistics}/>
          </Grid>
          <Grid xs={12} lg={12} display={"flex"}>
            <CasesStatisticsCard statistics={casesStatistics}/>
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <IntakesStatisticsCard statistics={intakesStatistics}/>
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <ProvidersStatisticsCard statistics={providersStatistics}/>
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <FirmDocumentsStatisticsCard
              statistics={firmDocumentFoldersStatistics}
            />
          </Grid>
          <Grid xs={12} lg={6} display={"flex"}>
            <TemplatesStatisticsCard statistics={templateFilesStatistics}/>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrganizationPage;
