import * as React from "react";
import { useState } from "react";
import { Organization } from "../../models/Organization";

import {
  Button,
  Typography,
  Stack,
  IconButton,
  Box,
} from "@mui/joy";

import { 
  Visibility, 
  VisibilityOff,
  ContentCopy 
} from "@mui/icons-material";

const SecretViewer: React.FC<{ organization: Organization }> = ({
  organization,
}) => {
  const [isSecretVisible, setIsSecretVisible] = useState(false);

  const toggleSecretVisibility = () => {
    setIsSecretVisible((prevState) => !prevState);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(organization.organizationSecret).then(() => {});
  };

  return (
    <Box sx={{ minHeight: 40}}>
      {isSecretVisible ? (
        <Stack direction="row" padding={0} alignItems="center" spacing={1}>
          <Button
            sx={{ p: 0 }}
            onClick={toggleSecretVisibility}
            variant="plain"
            color="neutral"
            startDecorator={<VisibilityOff sx={{ padding: "0px" }} />}
          />
          <Typography
            sx={{ py: 1, px: 0, wordBreak: "break-word", color: "white" }}
            level="body-sm"
          >
            {organization.organizationSecret}
          </Typography>
          <IconButton
            onClick={copyToClipboard}
            variant="plain"
            color="neutral"
            sx={{ p: 0 }}
          >
            <ContentCopy sx={{ fontSize: "16px" }}/>
          </IconButton>
        </Stack>
      ) : (
        <Button
          onClick={toggleSecretVisibility}
          variant="plain"
          color="neutral"
          sx={{ padding: "0px" }}
          startDecorator={<Visibility sx={{ padding: "0px" }} />}
        >
          Show Secret
        </Button>
      )}
    </Box>
  );
};

export default SecretViewer;
