export enum FileType {
  All = "All",
  CaseFile = "Case files",
  FirmDocuments = "Firm documents",
}

export const FileTypeMap: Record<string, number | null> = {
  [FileType.CaseFile]: 1,
  [FileType.FirmDocuments]: 3,
  [FileType.All]: null,
};
