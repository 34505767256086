import React, { FC } from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {Box, Typography } from "@mui/joy";

export interface SelectOption<T> {
  id: T;
  label: string;
  color?: 'warning' | 'danger' | 'success' | 'primary' | 'neutral' | 'pink' | 'lime' | 'yellow';
}

interface GenericSelectProps<T> {
  options: SelectOption<T>[];
  placeholder: string;
  inputValue: SelectOption<T>;
  setValue: (value: SelectOption<T>) => void;
  width?: string;
  size?: 'sm' | 'md' | 'lg';
}

const GenericSelect: FC<GenericSelectProps<any>> = ({
  options,
  placeholder,
  inputValue,
  setValue,
  width = "100%",
  size = "md",
}) => {
  const handleChange = (
    event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    id: string | null
  ) => {
    const option = options.find((option) => option.id === id);
    if (option) {
      setValue(option);
    }
  };

  return (
    <Select
      value={inputValue.id}
      onChange={(event, newValue) => handleChange(event, newValue as any)}
      placeholder={placeholder}
      sx={{ width }}
      size={size}
      renderValue={(selected) => {
        const selectedOption = options.find(option => option.id == selected?.value);
        return selectedOption ? (
          <Typography color={selectedOption.color}>
            {selectedOption.label}
          </Typography>
        ) : placeholder;
      }}
    >
      {options.map((option) => (
        <Option
          key={option.id as React.Key}
          value={option.id}
        >
          <Box component="span" sx={{ display: 'block' }}>
            <Typography color={option.color}>
              {option.label}
            </Typography>
          </Box>
        </Option>
      ))}
    </Select>
  );
};

export default GenericSelect;
