import { AxiosResponse } from "axios";
import { OrganizationWithStatistics } from "../models/OrganizationWithStatistics";
import { Paging } from "../models/Paging";
import useApi from "../services/ApiService";
import {OrganizationConfiguration} from "../models/OrganizationConfiguration/OrganizationConfiguration";
import {ImplementationType} from "../models/OrganizationConfiguration/ImplementationType";
import {
  Organization,
  OrganizationWithSecret,
} from "../models/Organization";

export interface GetOrganizationsQueryOptions {
  names?: string[];
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
  fetchAll?: boolean;
}

export class OrganizationsRepository {
  private api = useApi();
  async getOrganization(id: string): Promise<OrganizationWithStatistics> {
    try {
      const response = await this.api.get(
        `/api/Organizations/${id}/statistics`
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch organization with id: ${id}`);
    }
  }
  
  async getOrganizations(assignedUserId: string | null): Promise<{
    data: OrganizationWithSecret[];
    paging: Paging | null;
  }> {
    try {
      const response = await this.api.get("/api/Organizations/statistics/all", {
        params: {
          AssignedUserId: assignedUserId,
          OrderBy: "DateCreated",
          FilterSoftDeleted: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      throw new Error("Failed to fetch organizations");
    }
  }
  
  async getOrganizationsInfo(
    options: GetOrganizationsQueryOptions = {}
  ): Promise<{ data: Organization[]; paging: Paging | null }> {
    const {
      names = null,
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = "DateCreated",
      descending = true,
      fetchAll = true,
    } = options;
    try {
      const response = await this.api.get("/api/Organizations", {
        params: {
          Name: names,
          Id: ids,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
          FetchAll: fetchAll,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch organizations");
    }
  }

  async getOrganizationClientIds(organizationId: string): Promise<string[]> {
    try {
      const response = await this.api.get(
        `/api/Organizations/${organizationId}/clientIds`,
      );
      return response.data as string[];
    } catch (error) {
      throw new Error(`Failed to fetch organization client ids  of organization with id: ${organizationId}`);
    }
  }

  async getOrganizationConfiguration(id: string): Promise<OrganizationConfiguration> {
    try {
      const response = await this.api.get(
        `/api/Organizations/${id}/configuration`
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch organization configuration of organization with id: ${id}`);
    }
  }

  async getOrganizationImplementationType(id: string): Promise<ImplementationType> {
    try {
      const response = await this.api.get(
        `/api/Organizations/${id}/implementationType`
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch organization implementation type of organization with id: ${id}`);
    }
  }

  async createOrganization(
    name: string,
    intactId: string,
    secret: string
  ): Promise<OrganizationWithSecret> {
    try {
      const response: AxiosResponse<OrganizationWithSecret> =
        await this.api.post("/api/Organizations/", {
          name: name,
          intactId: intactId,
          organizationSecret: secret,
        });
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to create organization ${intactId} - ${name}`);
    }
  }

  async patchOrganizationAssignedUser(
    organizationId: string,
    userId: string,
  ): Promise<void> {
    try {
      return await this.api.patch("/api/Organizations/PatchOrganizationAssignedUser", {
        organizationId: organizationId,
        userId: userId,
      });
    } catch (error) {
      // Handle error
      throw new Error(`Failed to patch assigned user for organization`);
    }
  }

  async patchOrganizationConfiguration(
    organizationId: string,
    organizationConfiguration: OrganizationConfiguration,
  ): Promise<OrganizationConfiguration> {
    try {
      return await this.api.patch(`/api/Organizations/PatchOrganizationConfiguration`, {
        organizationId: organizationId,
        configurationPatch: organizationConfiguration,
      });
    } catch (error) {
      // Handle error
      throw new Error(`Failed to patch configuration for organization`);
    }
  }

  async deleteOrganization(organizationId: string): Promise<void> {
    try {
      return await this.api.delete(`/api/Organizations/${organizationId}`);
    } catch (error) {
      // Handle error
      throw new Error("Failed to delete organization");
    }
  }
}

export default OrganizationsRepository;
