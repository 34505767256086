import {useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import OrganizationsRepository from "../repositories/OrganizationsRepository";
import {DiscoveryCycleQueryParams} from "../repositories/DiscoveryCyclesRepository";
import {MigrationType} from "../models/Statistics/MigrationType";
import {MigrationEventStepType} from "../models/Statistics/MigrationEventStepType";
import OutlinedDiv from "../components/common/OutlinedDiv";
import DiscoveryCycleTable from "../components/DiscoveryCycles/DiscoveryCycleTable";
import {formatDate, getFormattedDate} from "../helpers/StringFormatters";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Stack,
  Switch,
  ToggleButtonGroup,
  Typography,
  Option,
  ChipDelete,
  IconButton,
  Snackbar
} from "@mui/joy";
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

export enum DiscoveryCycleFilterRangeType {
  OnlyLastTwoEvents = 'OnlyLastTwoEvents',
  Custom = 'Custom',
}

const DiscoveryCyclesPage = () => {
  const clientIdsQueryKey = "clientIds";
  const caseCyclesQueryKey = "caseCycles";
  const intakeCyclesQueryKey = "intakeCycles";
  const providerCyclesQueryKey = "intakeCycles";
  const firmDocumentCyclesQueryKey = "intakeCycles";
  const templateCyclesQueryKey = "intakeCycles";
  
  const organizationsRepository = new OrganizationsRepository();
  const queryClient = useQueryClient();
  const { organizationId } = useParams();
  const [clientIds, setClientIds] = useState<string[]>([]);
  
  const [fetchCaseCycles, setFetchCaseCycles] = useState(true);
  const [fetchIntakeCycles, setFetchIntakeCycles] = useState(true); // TODO: defaulte true
  const [fetchProviderCycles, setFetchProviderCycles] = useState(true);
  const [fetchFirmDocumentFolderCycles, setFetchFirmDocumentFolderCycles] = useState(true);
  const [fetchTemplateCycles, setFetchTemplateCycles] = useState(true);
  const [visibleCycles, setVisibleCycles] = useState({
    caseCycles: fetchCaseCycles,
    intakeCycles: fetchIntakeCycles,
    providerCycles: fetchProviderCycles,
    firmDocumentFolderCycles: fetchFirmDocumentFolderCycles,
    templateCycles: fetchTemplateCycles,
  });
  
  const [fetchInProgressEvents, setFetchInProgressEvents] = useState(true);
  const [fetchInterruptedEvents, setFetchInterruptedEvents] = useState(true);
  const [fetchFinishedEvents, setFetchFinishedEvents] = useState(true);

  const [showErrorSnackbar, setShowErrorSnackbar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
  const yesterday = new Date();
  yesterday.setUTCDate(yesterday.getUTCDate() - 1);
  
  const [queryParams, setQueryParams] = useState<DiscoveryCycleQueryParams>({
    clientIds: [],
    eventSteps: [MigrationEventStepType.Started, MigrationEventStepType.Interrupted, MigrationEventStepType.Finished],
    rangeType: DiscoveryCycleFilterRangeType.OnlyLastTwoEvents,
    fromDate: formatDate(yesterday),
    toDate: '',
    fromTime: '00:00',
    toTime: ''
  });
  
  const [shouldFetchDiscoveryCycles, setShouldFetchDiscoveryCycles] = useState(true);
  
  const clientIdsQuery = useQuery(
    [clientIdsQueryKey, organizationId],
    async () => {
      return await organizationsRepository.getOrganizationClientIds(organizationId!);
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (clientIdsQuery.data && clientIdsQuery.data !== clientIds) {
      setClientIds(clientIdsQuery.data);
      setQueryParams((prevState) => ({
        ...prevState,
        clientIds: clientIdsQuery.data
      }));
    }
  }, [ clientIdsQuery.data ]);

  const handleSelectClientId = (
    _: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null,
    value: string[]
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      clientIds: value
    }));
  };

  const handleUnSelectClientId = (
    guidToUnSelect: string
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      clientIds: prevState.clientIds.filter((guid) => guid !== guidToUnSelect)
    }));
  };

  const handleDateChange = (
    field: 'fromDate' | 'toDate'
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = event.target.value
      ? getFormattedDate(event.target.value, "yyyy-MM-dd")
      : null;

    setQueryParams((prev) => ({ ...prev, [field]: formattedDate }));
  };
  
  const handleTimeChange = (
    field: 'fromTime' | 'toTime'
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const handleRangeTypeChange = (newValue: DiscoveryCycleFilterRangeType) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      rangeType: newValue,
    }));
  };
  
  const handleSubmit = () => {
    // validate filters
    if (!fetchInProgressEvents && !fetchInterruptedEvents && !fetchFinishedEvents) {
      setShowErrorSnackbar(true);
      setErrorMessage("At least one selected event status (In Progress, Interrupted, or Finished) is required to search");
      return;
    }
    
    if (queryParams.clientIds.length === 0) {
      setShowErrorSnackbar(true);
      setErrorMessage("At least one selected client is required to search");
      return;
    }

    if (queryParams.rangeType === DiscoveryCycleFilterRangeType.Custom && !queryParams.fromDate) {
      setShowErrorSnackbar(true);
      setErrorMessage("Start date is required when the range type is set to Custom");
      return;
    }

    if (queryParams.rangeType === DiscoveryCycleFilterRangeType.Custom && !queryParams.toDate && queryParams.toTime !== '') {
      setShowErrorSnackbar(true);
      setErrorMessage("End Date is required when end time is provided");
      return;
    }
    
    // logic
    setShowErrorSnackbar(false);
    setShouldFetchDiscoveryCycles(true);
    const eventSteps: MigrationEventStepType[] = [];
    
    if (fetchInProgressEvents)
      eventSteps.push(MigrationEventStepType.Started);

    if (fetchInterruptedEvents)
      eventSteps.push(MigrationEventStepType.Interrupted);

    if (fetchFinishedEvents)
      eventSteps.push(MigrationEventStepType.Finished);
    
    setQueryParams((prev) => ({ ...prev, eventSteps }));
    
    if (fetchCaseCycles)
      queryClient.invalidateQueries(caseCyclesQueryKey);
    
    if (fetchIntakeCycles)
      queryClient.invalidateQueries(intakeCyclesQueryKey);

    if (fetchProviderCycles)
      queryClient.invalidateQueries(providerCyclesQueryKey);

    if (fetchFirmDocumentFolderCycles)
      queryClient.invalidateQueries(firmDocumentCyclesQueryKey);

    if (fetchTemplateCycles)
      queryClient.invalidateQueries(templateCyclesQueryKey);

    setVisibleCycles({
      caseCycles: fetchCaseCycles,
      intakeCycles: fetchIntakeCycles,
      providerCycles: fetchProviderCycles,
      firmDocumentFolderCycles: fetchFirmDocumentFolderCycles,
      templateCycles: fetchTemplateCycles,
    });
  };
  
  const handleResetToDefault = () => {
    setFetchCaseCycles(true);
    setFetchIntakeCycles(true);
    setFetchProviderCycles(true);
    setFetchFirmDocumentFolderCycles(true);
    setFetchTemplateCycles(true);

    setVisibleCycles({
      caseCycles: true,
      intakeCycles: true,
      providerCycles: true,
      firmDocumentFolderCycles: true,
      templateCycles: true,
    });

    setFetchInProgressEvents(true);
    setFetchInterruptedEvents(true);
    setFetchFinishedEvents(true);

    handleRangeTypeChange(DiscoveryCycleFilterRangeType.OnlyLastTwoEvents);
    setQueryParams((prevState) => ({
      ...prevState,
      clientIds: clientIds
    }));

    const yesterdayDate = new Date();
    yesterdayDate.setUTCDate(yesterdayDate.getUTCDate() - 1);
    setQueryParams((prevState) => ({
      ...prevState,
      fromDate: formatDate(yesterdayDate),
      toDate: '',
      fromTime: '00:00',
      toTime: ''
    }));
    
    setShowErrorSnackbar(false);
    setShouldFetchDiscoveryCycles(true);
    
    const eventSteps: MigrationEventStepType[] = [];
    eventSteps.push(MigrationEventStepType.Started);
    eventSteps.push(MigrationEventStepType.Interrupted);
    eventSteps.push(MigrationEventStepType.Finished);
    setQueryParams((prev) => ({ ...prev, eventSteps }));
    
    queryClient.invalidateQueries(caseCyclesQueryKey);
    queryClient.invalidateQueries(intakeCyclesQueryKey);
    queryClient.invalidateQueries(providerCyclesQueryKey);
    queryClient.invalidateQueries(firmDocumentCyclesQueryKey);
    queryClient.invalidateQueries(templateCyclesQueryKey);
  };

  const handleCloseErrorSnackbar = () => {
    setShowErrorSnackbar(false);
  };

  return (
    <Box sx={{
      width: {
        xs: '100vw',
        lg: 'calc(100vw - 15vw)',
      },
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: {
        xs: 0,
        lg: '15vw',
      },
      py: 3,
      px: 6,
      overflowY: 'auto',
    }}>
      <Box
        sx={{
          display: "flex",
          mt: 1,
          mb: 1.5,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Discovery Cycles</Typography>
        <Stack direction="row" spacing={1}>
          <Button
            size="md"
            sx={{ width: 145 }}
            onClick={() => handleResetToDefault()}
          >
            Reset To Default
          </Button>
          <Button
            size="md" 
            sx={{ width: 145 }}
            onClick={() => {
             
              handleSubmit()
            }}
          >
            Submit
          </Button>
        </Stack>
      </Box>

      <Card variant={"outlined"}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography level="h4" component="div">
              Filters
            </Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 2 }} />

          <Grid container spacing={1} sx={{ flexGrow: 1, mb: 0.2 }}>
            <Grid xs={6} md={2.5} lg={2.5}>
              <OutlinedDiv label="Discovery Cycle Type">
                <Stack spacing={1} sx={{ mb: -0.5}}>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Cases</FormLabel>
                    <Switch 
                      checked={fetchCaseCycles}
                      onChange={(e) => setFetchCaseCycles(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Intakes</FormLabel>
                    <Switch
                      checked={fetchIntakeCycles}
                      onChange={(e) => setFetchIntakeCycles(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Providers</FormLabel>
                    <Switch
                      checked={fetchProviderCycles}
                      onChange={(e) => setFetchProviderCycles(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Firm Documents</FormLabel>
                    <Switch
                      checked={fetchFirmDocumentFolderCycles}
                      onChange={(e) => setFetchFirmDocumentFolderCycles(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Templates</FormLabel>
                    <Switch
                      checked={fetchTemplateCycles}
                      onChange={(e) => setFetchTemplateCycles(e.target.checked)}
                    />
                  </FormControl>
                </Stack>

              </OutlinedDiv>
            </Grid>

            <Grid xs={6} md={2.5} lg={2.5}>
              <OutlinedDiv label="Event Status">
                <Stack spacing={1} sx={{ mb: -0.5}}>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>In Progress</FormLabel>
                    <Switch
                      checked={fetchInProgressEvents}
                      onChange={(e) => setFetchInProgressEvents(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Interrupted</FormLabel>
                    <Switch
                      checked={fetchInterruptedEvents}
                      onChange={(e) => setFetchInterruptedEvents(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Finished</FormLabel>
                    <Switch
                      checked={fetchFinishedEvents}
                      onChange={(e) => setFetchFinishedEvents(e.target.checked)}
                    />
                  </FormControl>
                </Stack>

              </OutlinedDiv>
            </Grid>
            
            <Grid xs={12} md={7} lg={7}>
              <OutlinedDiv label="Range">
                <ToggleButtonGroup
                  value={queryParams.rangeType}
                  onChange={(e, newValue) => {
                    if (newValue !== null && newValue !== queryParams.rangeType) {
                      handleRangeTypeChange(newValue as DiscoveryCycleFilterRangeType);
                    }
                  }}
                  sx={{ mt: 0.5, width: "100%", minHeight: "50px" }}
                >
                  <Button fullWidth value={DiscoveryCycleFilterRangeType.OnlyLastTwoEvents}>
                    Only Last Two Events Per Client
                  </Button>
                  <Button fullWidth value={DiscoveryCycleFilterRangeType.Custom}>
                    Custom
                  </Button>
                </ToggleButtonGroup>

                <Box
                  sx={{ mt: 2 }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <OutlinedDiv
                        label="Start Date"
                        disabled={ queryParams.rangeType === DiscoveryCycleFilterRangeType.OnlyLastTwoEvents }
                      >
                        <Stack direction="row" spacing={1}>
                          <Input
                            sx={{ width: "58%" }}
                            disabled={queryParams.rangeType === DiscoveryCycleFilterRangeType.OnlyLastTwoEvents}
                            size={"sm"}
                            type="date"
                            value={
                              queryParams.fromDate
                                ? getFormattedDate(queryParams.fromDate, "yyyy-MM-dd")
                                : ""
                            }
                            onChange={handleDateChange('fromDate')}
                          />
                          <Input
                            sx={{ width: "42%" }}
                            disabled={ queryParams.rangeType === DiscoveryCycleFilterRangeType.OnlyLastTwoEvents }
                            size={"sm"}
                            type={"time"}
                            value={queryParams.fromTime}
                            onChange={handleTimeChange('fromTime')}
                            endDecorator={
                              <IconButton
                                sx={{ ml: -1  }}
                                onClick={() => setQueryParams({ ...queryParams, fromTime: '' })}>
                                <CloseIcon sx={{ color: 'white' }} />
                              </IconButton>
                            }
                          />
                        </Stack>
                      </OutlinedDiv>
                    </Grid>

                    <Grid xs={6}>
                      <OutlinedDiv 
                        label="End Date"
                        disabled={ queryParams.rangeType === DiscoveryCycleFilterRangeType.OnlyLastTwoEvents }
                      >
                        <Stack direction="row" spacing={1}>
                          <Input
                            sx={{ width: "58%" }}
                            disabled={queryParams.rangeType === DiscoveryCycleFilterRangeType.OnlyLastTwoEvents}
                            size={"sm"}
                            type="date"
                            value={
                              queryParams.toDate
                                ? getFormattedDate(queryParams.toDate, "yyyy-MM-dd")
                                : ""
                            }
                            onChange={handleDateChange('toDate')}
                          />
                          <Input
                            sx={{ width: "42%" }}
                            disabled={ queryParams.rangeType === DiscoveryCycleFilterRangeType.OnlyLastTwoEvents }
                            size={"sm"}
                            type={"time"}
                            value={queryParams.toTime}
                            onChange={handleTimeChange('toTime')}
                            endDecorator={
                              <IconButton
                                sx={{ ml: -1  }}
                                onClick={() => setQueryParams({ ...queryParams, toTime: '' })}>
                                <CloseIcon sx={{ color: 'white' }} />
                              </IconButton>
                            }
                          />
                        </Stack>
                      </OutlinedDiv>
                    </Grid>
                  </Grid>
                </Box>
              </OutlinedDiv>
            </Grid>
          </Grid>
          
          <OutlinedDiv label="Selected clients">
            <Select
              multiple
              value={queryParams.clientIds}
              onChange={handleSelectClientId}
              placeholder="Select clients"
              sx={{ width: "100%", minWidth: "5rem", py: 1}}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.25rem",
                    flexWrap: "wrap",
                    maxHeight: "100px",
                    overflowY: "auto",
                  }}
                >
                  {selected.map((selectedOption) => (
                    <Chip 
                      variant="soft"
                      color="primary"
                      endDecorator={
                        <ChipDelete
                          onDelete={() => handleUnSelectClientId(selectedOption.value)}
                        />
                      }
                    >
                      {selectedOption.label}
                    </Chip>
                  ))}
                </Box>
              )}
              slotProps={{
                listbox: {
                  sx: {
                    width: "100%",
                  },
                },
              }}
            >
              {clientIds.map((guid) => (
                <Option key={guid} value={guid}>
                  {guid}
                </Option>
              ))}
            </Select>
          </OutlinedDiv>
          
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mt: 2 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography level="h4" component="div">
              Results
            </Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 0 }} />

      {visibleCycles.caseCycles && clientIds.length != 0 ? (
        <DiscoveryCycleTable
          label="Case cycles"
          queryKey={caseCyclesQueryKey}
          migrationType={MigrationType.CasesMigration}
          organizationId={organizationId ?? ""}
          queryParams={queryParams}
          shouldFetchDiscoveryCycles={shouldFetchDiscoveryCycles}
          setShouldFetchDiscoveryCycles={setShouldFetchDiscoveryCycles}
        />
      ) : null}

      {visibleCycles.intakeCycles  && clientIds.length != 0 ? (
        <DiscoveryCycleTable
          label="Intake cycles"
          queryKey={intakeCyclesQueryKey}
          migrationType={MigrationType.IntakesMigration}
          organizationId={organizationId ?? ""}
          queryParams={queryParams}
          shouldFetchDiscoveryCycles={shouldFetchDiscoveryCycles}
          setShouldFetchDiscoveryCycles={setShouldFetchDiscoveryCycles}
        />
      ) : null}

      {visibleCycles.providerCycles  && clientIds.length != 0 ? (
        <DiscoveryCycleTable
          label="Provider cycles"
          queryKey={providerCyclesQueryKey}
          migrationType={MigrationType.ProvidersMigration}
          organizationId={organizationId ?? ""}
          queryParams={queryParams}
          shouldFetchDiscoveryCycles={shouldFetchDiscoveryCycles}
          setShouldFetchDiscoveryCycles={setShouldFetchDiscoveryCycles}
        />
      ) : null}

      {visibleCycles.firmDocumentFolderCycles  && clientIds.length != 0 ? (
        <DiscoveryCycleTable
          label="Firm document cycles"
          queryKey={firmDocumentCyclesQueryKey}
          migrationType={MigrationType.FirmDocumentsMigration}
          organizationId={organizationId ?? ""}
          queryParams={queryParams}
          shouldFetchDiscoveryCycles={shouldFetchDiscoveryCycles}
          setShouldFetchDiscoveryCycles={setShouldFetchDiscoveryCycles}
        />
      ) : null}

      {visibleCycles.templateCycles  && clientIds.length != 0 ? (
        <DiscoveryCycleTable
          label="Template cycles"
          queryKey={templateCyclesQueryKey}
          migrationType={MigrationType.TemplatesMigration}
          organizationId={organizationId ?? ""}
          queryParams={queryParams}
          shouldFetchDiscoveryCycles={shouldFetchDiscoveryCycles}
          setShouldFetchDiscoveryCycles={setShouldFetchDiscoveryCycles}
        />
      ) : null}

        </CardContent>
      </Card>

      <Snackbar
        color="danger"
        variant="solid"
        sx={{ mr: 2}}
        size="md"
        open={showErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
        startDecorator={
          <ErrorIcon sx ={{fontSize: 30}}/>
        }
        endDecorator={
          <IconButton size="sm" onClick={handleCloseErrorSnackbar}>
            <CloseIcon sx={{ color: 'white' }}/>
          </IconButton>
        }
      >
        <Typography sx={{ color: 'white' }}>
          {errorMessage}
        </Typography>
      </Snackbar>
    </Box>
  )
};

export default DiscoveryCyclesPage;