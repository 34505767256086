import { Box, CssBaseline, CssVarsProvider } from "@mui/joy";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import AdminConsentPage from "./pages/AdminConsentPage";
import AdminConsentResultPage from "./pages/AdminConsentResultPage";
import theme from "./theme/CustomTheme";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <CssVarsProvider 
          disableTransitionOnChange
          theme={theme}
        >
          <CssBaseline />
          <Box sx={{ display: "flex", minHeight: "100dvh" }}>
            <Routes>
              <Route path="/adminconsent" element={<AdminConsentPage />} />
              <Route
                path="/adminconsent/result"
                element={<AdminConsentResultPage />}
              />
              <Route path="/" element={<Navigate to="/organizations" />} />
              <Route
                path="/organizations"
                element={<DashboardPage selectedNavigation="Organizations" />}
              />
              <Route
                path="/organization/:organizationId"
                element={<DashboardPage selectedNavigation="Overview" />}
              />
              <Route
                path="/organization/:organizationId/discovery-cycles"
                element={<DashboardPage selectedNavigation="Discovery Cycles" />}
              />
              <Route
                path="/organization/:organizationId/configuration"
                element={
                  <DashboardPage selectedNavigation="Organization Configuration" />
                }
              />
              <Route
                path="/organization/:organizationId/client-configurations"
                element={
                  <DashboardPage selectedNavigation="Client Configurations" />
                }
              />
              <Route
                path="/organization/:organizationId/clients"
                element={<DashboardPage selectedNavigation="Clients" />}
              />
              <Route
                path="/organization/:organizationId/client-configuration/create"
                element={
                  <DashboardPage selectedNavigation="Client Configuration" />
                }
              />
              <Route
                path="/organization/:organizationId/client-configuration/:configurationId"
                element={
                  <DashboardPage selectedNavigation="Client Configuration" />
                }
              />
              <Route
                path="/organization/:organizationId/cases"
                element={<DashboardPage selectedNavigation="Cases" />}
              />
              <Route
                path="/organization/:organizationId/case/:caseId"
                element={<DashboardPage selectedNavigation="Case" />}
              />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/users"
                element={<DashboardPage selectedNavigation="Users" />}
              />
              <Route
                path="/users/:userId"
                element={<DashboardPage selectedNavigation="UserDetails" />}
              />
            </Routes>
          </Box>
        </CssVarsProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
