import { Box } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import UsersRepository from "../repositories/UsersRepository";
import { User } from "../models/User";
import { OrganizationsRepository } from "../repositories/OrganizationsRepository";
import { OrganizationWithSecret } from "../models/Organization";
import UserDetailsForm from "../components/Users/UserDetailsForm";

interface UserPageProps {
  userId: string;
}

const UserPage: React.FC<UserPageProps> = ({ userId }) => {
  const [user, setUser] = useState<User>({} as User);
  const [organizations, setOrganizations] = useState<
    OrganizationWithSecret[]
  >([]);
  const usersRepository = new UsersRepository();
  const organizationsRepository = new OrganizationsRepository();

  const userQuery = useQuery(
    ["user", userId],
    async () => {
      return await usersRepository.getUserById(userId);
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    }
  );

  const organizationsQuery = useQuery(
    "organizations",
    async () => {
      return await organizationsRepository.getOrganizations(null);
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (userQuery.data) {
      setUser(userQuery.data);
    }
    if (organizationsQuery.data?.data) {
      setOrganizations(organizationsQuery.data?.data);
    }
  }, [organizationsQuery, userQuery]);

  return (
    <Box minWidth="100%" height={"100%"} padding={0}>
      <UserDetailsForm
        initUser={user}
        key={user.id}
        organizations={organizations}
      />
    </Box>
  );
};

export default UserPage;
