import { Sheet, Typography, styled } from "@mui/joy";
import React from "react";

interface OutlinedDivProps {
  label: string;
  children: React.ReactNode;
  disabled?: boolean; 
}

const StyledPaper = styled(Sheet, {
  shouldForwardProp: (prop) => prop !== "disabled", 
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: "16px",
  position: "relative",
  flexGrow: 1,
  width: "100%",
  height: "100%",
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? "none" : "auto",
}));

const StyledLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  position: "absolute",
  top: "-12px",
  left: "12px",
  padding: "0 4px",
  color: disabled ? theme.palette.text.secondary : theme.palette.text.primary,
  zIndex: 1,
  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.surface,
    height: "54%",
    width: "100%",
    zIndex: -1,
  },
}));

const OutlinedDiv: React.FC<OutlinedDivProps> = ({ label, children, disabled = false }) => {
  return (
    <StyledPaper disabled={disabled}>
      <StyledLabel disabled={disabled}>
        <Typography level="title-md">{label}</Typography>
      </StyledLabel>
      {children}
    </StyledPaper>
  );
};
export default OutlinedDiv;
